<template>
  <div>
    <header-top-dashboard
      primary
      :custom-right-content="true"
      :show-back-button="true"
    >
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <v-card-text class="card-padding">
              <v-form>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>
                      Delivery Details - #{{ orderDetails.order_reference }}
                    </h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Shop Name</label
                    >
                    <v-text-field
                      disabled
                      outlined
                      placeholder="Shop Name"
                      v-model="shopName"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Shop Owner</label
                    >
                    <v-text-field
                      disabled
                      outlined
                      placeholder="Shop Owner"
                      v-model="shopOwnerName"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Delivery Address</label
                    >
                    <label class="ml-3 d-block">{{ deliveryAddress }}</label>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0 mb-8">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Delivery Status</label
                    >
                    <label class="ml-3 d-block">{{
                      deliveryStatusResolved
                    }}</label>
                  </v-col>

                  <v-col cols="12" md="3" class="py-0">
                    <v-checkbox
                      disabled
                      v-model="isCreditOrder"
                      label="Is Credit Order"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="3" class="py-0 mb-8">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Order Sub Total</label
                    >
                    <label class="ml-3 d-block">
                      R {{ formatAmount(orderDetails.total) }}
                    </label>
                  </v-col>

                  <v-col cols="12" md="3" class="py-0 mb-8">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Delivery Fee</label
                    >
                    <label class="ml-3 d-block">
                      R {{ formatAmount(orderDetails.delivery_fee) }}
                    </label>
                  </v-col>

                  <v-col cols="12" md="3" class="py-0 mb-8">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Order Total</label
                    >
                    <label class="ml-3 d-block">
                      R {{ formatAmount(Number(orderDetails.total) + Number(orderDetails.delivery_fee)) }}
                    </label>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Delivery Date</label
                    >

                    <v-menu
                      :disabled="!disableDeliveryDate"
                      ref="deliveryDateMenu"
                      v-model="deliveryDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :disabled="!disableDeliveryDate"
                          outlined
                          dense
                          v-model="deliveryDate"
                          placeholder="Select delivery date..."
                          prepend-icon="ni ni-calendar-grid-58"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="input-style font-size-input text-light-input placeholder-light"
                          :error-messages="
                            getFieldValidationErrors(v$.deliveryDate.$errors)
                          "
                          @input="v$.deliveryDate.$touch"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="deliveryDate"
                        @input="deliveryDateMenu = false"
                        :min="getDateTodayIso()"
                        :max="getMaxDeliveryDate()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Assigned Driver</label
                    >
                    <v-autocomplete
                      :disabled="!disableAssignedDriver"
                      v-model="assignedDriver"
                      :items="driverUserList"
                      item-value="driverId"
                      item-text="friendlyName"
                      clearable
                      outlined
                      dense
                      placeholder="Select Driver..."
                      class="input-style font-size-input text-light-input placeholder-light"
                      :error-messages="
                        getFieldValidationErrors(v$.assignedDriver.$errors)
                      "
                      @input="v$.assignedDriver.$touch"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Products</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col v-if="orderProducts.length == 0">
                    <p>This order has no products.</p>
                  </v-col>
                  <v-col
                    v-else
                    v-for="(product, index) in orderProducts"
                    :key="index"
                    cols="12"
                    md="3"
                    class="py-0"
                  >
                    <v-card class="card-shadow mb-6">
                      <v-img
                        height="200"
                        :src="getProductPrimaryImageUri(product)"
                      >
                      </v-img>
                      <v-card-text class="card-padding">
                        <h2 class="text-h2 text-typo font-weight-600 mb-0">
                          <v-avatar color="#52c3c3" size="30">
                            <small>{{ product.pivot.quantity || 0 }}</small>
                          </v-avatar>
                          {{ product.name }} {{ product.weight }} x {{ product.package_units }}
                        </h2>
                        <p></p>
                        <strong>
                          Unit Price: R {{ formatAmount(product.pivot.retail_price) }} <br />
                          Total: R
                          {{
                            formatAmount(
                              product.pivot.retail_price * product.pivot.quantity
                            )
                          }}
                        </strong>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-btn
                  v-if="showMainActionButton"
                  @click="updateDelivery(submitButton.status)"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  :loading="buttons.submit.isLoading"
                  class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                  color="#5e72e4"
                  >{{ submitButton.title }}
                </v-btn>
                <v-btn
                  v-if="deliveryStatus === 'in_transit'"
                  @click="showCancellationReason"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  :loading="buttons.submit.isLoading"
                  class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                  color="#f5365c"
                  >Undelivered
                </v-btn>
                <v-btn
                  v-if="showRescheduleButton"
                  @click="updateDelivery('delivery_rescheduled')"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  :loading="buttons.reschedule.isLoading"
                  class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                  color="#e4cb5e"
                  >Reschedule Delivery
                </v-btn>
                <v-btn
                  v-if="showCancelButton"
                  @click="showCancellationReason"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  :loading="buttons.cancel.isLoading"
                  class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                  color="#f5365c"
                  >Cancel Delivery
                </v-btn>
                <v-row class="mt-5" v-if="showRepackaging">
                  <v-col cols="12" md="2">
                    <v-btn
                      v-if="!isDeliveryCancelled"
                      @click="requestRepackaging"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      :loading="buttons.requestRepackaging.isLoading"
                      class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                      color="#5ed4e4"
                      >Request Re-packaging
                    </v-btn>
                  </v-col>
                  <v-col cols="12" ms="7">
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Re-packaging note..."
                      v-model="repackageNotes"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <!-- <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Order Packer</label
                    > -->
                    <v-select
                      :disabled="!disableOrderPacker"
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Choose Packer"
                      :items="packers"
                      item-text="name"
                      item-value="id"
                      v-model="orderPacker"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="mt-1" v-if="showRepackaging">
                  <v-col cols="12">
                    <div class="ml-3">
                      <h4>Order Notes</h4>
                      <div class="ml-5 mt-3" v-if="showOrderNotes">
                        <span
                          v-for="(note, index) in orderNotes"
                          :key="`note-${index}`"
                        >
                          <p>
                            <span style="color: #ff0000">
                              {{ getNotePrefix(note) }}</span
                            >: {{ note.text }}
                          </p>
                        </span>
                      </div>
                      <div v-else>There are no notes for this order.</div>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mt-5" v-if="showDeliveryNotes">
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Delivery note..."
                      v-model="deliveryNote"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-2" v-if="showDeliveryNotes">
                  <v-col cols="12">
                    <div class="ml-3">
                      <h4>Delivery Notes</h4>
                      <div
                        class="ml-5 mt-3"
                        v-if="showDeliveryNotes && deliveryNotes.length > 0"
                      >
                        <span
                          v-for="(note, index) in deliveryNotes"
                          :key="`note-${index}`"
                        >
                          <p>
                            <span style="color: #ff0000">
                              {{ getNotePrefix(note) }}</span
                            >: {{ note.text }}
                          </p>
                        </span>
                      </div>
                      <div v-else>There are no notes for this delivery.</div>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="dialogCancellationReason" max-width="500px">
        <v-card class="card-shadow card-padding">
          <v-card-title class="pt-0 text-h4 text-typo justify-center"
            >Reason</v-card-title
          >
          <v-card-text>
            Please provice a cancellation / undelivered reason below.
            <v-text-field
              v-model="cancellationReason"
              outlined
              class="input-style font-size-input text-light-input placeholder-light"
              placeholder="Reason..."
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn
              @click="closeCancellationReason"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-600 text-capitalize btn-ls btn-danger py-3 px-6 rounded-sm"
              color="#f5365c"
              >Back</v-btn
            >

            <v-btn
              @click="updateDelivery('cancel')"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-600 text-capitalize btn-ls btn-primary py-3 px-6 rounded-sm"
              color="#5e72e4"
              >Submit</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogCollectedOrderTotal" max-width="500px">
        <v-card class="card-shadow card-padding">
          <v-card-title class="pt-0 text-h4 text-typo justify-center"
            >Collected Amount</v-card-title
          >
          <v-card-text>
            Please enter the amount you collected below.
            <v-text-field
              v-model="collectedOrderTotal"
              outlined
              type="number"
              class="input-style font-size-input text-light-input placeholder-light"
              placeholder="0"
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn
              @click="closeCancellationReason"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-600 text-capitalize btn-ls btn-danger py-3 px-6 rounded-sm"
              color="#f5365c"
              >Back</v-btn
            >

            <v-btn
              @click="updateDelivery('perform_delivered')"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-600 text-capitalize btn-ls btn-primary py-3 px-6 rounded-sm"
              color="#5e72e4"
              >Submit</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";

export default {
  name: "deliveries-edit",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    HeaderTopDashboard,
  },
  validations() {
    return {
      assignedDriver: { required },
      deliveryDate: { required },
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      assignedDriver: null,
      buttons: {
        cancel: {
          isLoading: false,
        },
        reschedule: {
          isLoading: false,
        },
        submit: {
          isLoading: false,
        },
        requestRepackaging: {
          isLoading: false,
        },
        submitDeliveryNote: {
          isLoading: false,
        },
      },
      deliveryAddress: "N/A",
      deliveryDate: "",
      deliveryDateMenu: false,
      deliveryStatus: "draft",
      driverList: [],
      driverUserList: [],
      orderDetails: {},
      orderProducts: [],
      shopName: "",
      shopOwnerName: "",
      orderNotes: [],
      repackageNotes: "",
      packers: [],
      orderPacker: null,
      deliveryNotes: [],
      deliveryNote: "",
      dialogCancellationReason: null,
      cancellationReason: "",
      dialogCollectedOrderTotal: null,
      collectedOrderTotal: 0,
      isCreditOrder: false,
    };
  },
  computed: {
    ...mapStores(useSessionStore),
    canBeShiped() {
      if (["review_and_assign_driver"].includes(this.deliveryStatus)) {
        return true;
      }

      return false;
    },

    deliveryStatusResolved() {
      const obj = this.deliveryStatuses.find(
        (element) => element.key == this.deliveryStatus
      );
      return obj.value;
    },

    disableAssignedDriver() {
      if (this.sessionStore.user.roles) {
        return (
          this.sessionStore.user.roles.includes("super_admin") ||
          this.sessionStore.user.roles.includes("admin") ||
          this.sessionStore.user.roles.includes("warehouse_manager")
        );
      }

      return false;
    },

    disableDeliveryDate() {
      if (this.deliveryStatus === "awaiting_delivery") {
        return false;
      }

      if (this.sessionStore.user.roles) {
        return (
          this.sessionStore.user.roles.includes("super_admin") ||
          this.sessionStore.user.roles.includes("admin") ||
          this.sessionStore.user.roles.includes("warehouse_manager")
        );
      }

      return false;
    },

    isDeliveryCancelled() {
      if (
        [
          "cancelled_process_refund_and_restore_stock",
          "cancelled_restore_stock",
        ].includes(this.deliveryStatus)
      ) {
        return true;
      }

      return false;
    },

    isDeliveryCancelledOrDelivered() {
      if (
        this.isDeliveryCancelled ||
        ["delivered"].includes(this.deliveryStatus)
      ) {
        return true;
      }

      return false;
    },

    disableOrderPacker() {
      if (this.sessionStore.user.roles) {
        return (
          this.sessionStore.user.roles.includes("super_admin") ||
          this.sessionStore.user.roles.includes("admin") ||
          this.sessionStore.user.roles.includes("warehouse_manager")
        );
      }

      return false;
    },

    showMainActionButton() {
      if (
        this.deliveryStatus === "awaiting_payment" ||
        this.deliveryStatus === "delivered" ||
        this.isDeliveryCancelled
      ) {
        return false;
      }

      return true;
    },

    showOrderNotes() {
      return this.orderNotes.length > 0;
    },

    showRepackaging() {
      return (
        this.deliveryStatus === "draft" ||
        this.deliveryStatus === "review_and_assign_driver"
      );
    },

    showDeliveryNotes() {
      return (
        this.deliveryStatus === "undelivered" ||
        this.deliveryStatus === "delivery_rescheduled" ||
        this.deliveryStatus === "in_transit" ||
        this.deliveryStatus === "cancelled"
      );
    },

    showRescheduleButton() {
      if (
        this.sessionStore.user.roles &&
        this.sessionStore.user.roles.includes("driver")
      ) {
        return false;
      }

      if (
        this.deliveryStatus === "awaiting_payment" ||
        this.deliveryStatus === "delivered" ||
        this.isDeliveryCancelled
      ) {
        return false;
      }

      return (
        !this.isDeliveryCancelledOrDelivered &&
        this.deliveryStatus !== "review_and_assign_driver"
      );
    },

    showCancelButton() {
      if (
        this.sessionStore.user.roles &&
        this.sessionStore.user.roles.includes("driver")
      ) {
        return false;
      }

      return !(
        this.isDeliveryCancelledOrDelivered ||
        this.deliveryStatus === "awaiting_payment" ||
        this.deliveryStatus === "delivered"
      );
    },

    submitButton() {
      const result = {
        title: "",
        status: "",
      };

      switch (this.deliveryStatus) {
        case "draft": {
          break;
        }
        case "review_and_assign_driver": {
          result.title = "Assign Driver";
          result.status = "awaiting_delivery";
          break;
        }
        case "awaiting_delivery": {
          result.title = "Shipped";
          result.status = "in_transit";
          break;
        }
        case "in_transit": {
          result.title = "Delivered";
          result.status = "show_delivery_total_modal";
          // if (this.orderDetails.payment_type === "cash_on_delivery") {
          //   result.status = "awaiting_payment";
          // } else {
          //   result.status = "delivered";
          // }
          break;
        }
        case "undelivered":
        case "delivery_rescheduled": {
          result.title = "Awaiting Delivery";
          result.status = "review_and_assign_driver";
          break;
        }
        case "delivered": {
          break;
        }
        // case "delivery_rescheduled": {
        //   break;
        // }
        // case "cancelled_process_refund_and_restore_stock": {
        //   break;
        // }
        // case "cancelled_restore_stock": {
        //   break;
        // }
      }

      return result;
    },
  },
  methods: {
    closeCancellationReason() {
      this.dialogCancellationReason = false;
    },

    showCancellationReason() {
      this.dialogCancellationReason = true;
    },

    closeCollectedOrderTotal() {
      this.dialogCollectedOrderTotal = false;
    },

    showCollectedOrderTotal() {
      this.dialogCollectedOrderTotal = true;
    },

    formatAmount(value) {
      if (!value) return 0;

      if (typeof value === "string") {
        return Number(value).toFixed(2);
      }

      return value.toFixed(2);
    },

    fetchPackers() {
      const body = [
        {
          field: "role",
          operator: "=",
          value: "packer",
        },
      ];

      this.$http
        .post("/users/search", body, this.sessionStore.getHttpConfig)
        .then((result) => {
          this.packers.splice(0);
          for (let i = 0; i < result.data.data.length; i++) {
            this.packers.push({
              id: result.data.data[i].id,
              name: `${result.data.data[i].firstname} ${result.data.data[i].lastname}`,
            });
          }
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            `Unable to fetch packers. Please refresh or contact support if the error persists.`
          );
        });
    },

    getFieldValidationErrors(errors) {
      const messages = [];

      for (let i = 0; i < errors.length; i++) {
        messages.push(`${errors[i].$message}`);
      }

      return messages;
    },

    requestRepackaging() {
      if (!this.repackageNotes) {
        this.$notify.warning(
          "Please provide a re-packaging note before submitting."
        );
        return;
      }

      this.buttons.requestRepackaging.isLoading = true;

      this.$http
        .post(
          `/orders/${this.orderDetails.id}/notes`,
          { text: this.repackageNotes },
          this.sessionStore.getHttpConfig
        )
        .then(async () => {
          this.$http
            .patch(
              `/deliveries/${this.id}`,
              { status: "packaging", packer: this.orderPacker },
              this.sessionStore.getHttpConfig
            )
            .then(async () => {
              this.$notify.success("Re-packaging requested successfully!");
              this.$router.push("/deliveries");
            })
            .catch((error) => {
              this.handleHttpError(
                error,
                "An error occurred while updating the delivery. Please try again later or contact support."
              );
            })
            .finally(() => {
              this.buttons.requestRepackaging.isLoading = false;
            });
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while updating the delivery note. Please try again later or contact support."
          );
        });
    },

    async updateDelivery(deliveryStatus) {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect && deliveryStatus !== "cancel") {
        this.$notify.warning(
          "Please resolve the validation errors before proceeding!"
        );
        return;
      }

      if (deliveryStatus === "show_delivery_total_modal") {
        this.showCollectedOrderTotal();
        return;
      }

      this.buttons.submit.isLoading = true;

      const deliveryBody = {
        driver_id: this.assignedDriver,
        status: deliveryStatus,
        delivery_date: this.deliveryDate,
      };

      if (deliveryStatus === "perform_delivered") {
        if (this.orderDetails.payment_type === "cash_on_delivery") {
          deliveryBody.status = "awaiting_payment";
        } else {
          deliveryBody.status = "delivered";
        }
        deliveryBody.collected_total = this.collectedOrderTotal;
      }

      if (this.deliveryNote || deliveryStatus === "cancel") {
        deliveryBody.message = this.cancellationReason;
      }

      this.$http
        .patch(
          `/deliveries/${this.id}`,
          deliveryBody,
          this.sessionStore.getHttpConfig
        )
        .then(async () => {
          if (this.deliveryNote) {
            const body = {
              text: this.deliveryNote,
            };

            this.$http
              .post(
                `/deliveries/${this.id}/notes`,
                body,
                this.sessionStore.getHttpConfig
              )
              .then(async (response) => {
                this.deliveryNotes.push(response.data.data[0]);
                this.$notify.success("Delivery updated successfully!");
                this.$router.push("/deliveries");
              })
              .catch((error) => {
                this.handleHttpError(
                  error,
                  "An error occurred while submitting the delivery note. Please try again later or contact support."
                );
              })
              .finally(() => {
                this.buttons.submit.isLoading = false;
              });
          } else {
            this.$notify.success("Delivery updated successfully!");
            this.$router.push("/deliveries");
          }
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while updating the delivery. Please try again later or contact support."
          );
        })
        .finally(() => {
          this.buttons.submit.isLoading = false;
        });
    },

    getProductPrimaryImageUri(product) {
      let primaryImageUri = this.defaultImage;

      const primaryImage = product.images.find(
        (e) => e.designation == "primary"
      );

      if (primaryImage) {
        primaryImageUri = `${this.sessionStore.getApiHost}${primaryImage.image_uri}`;
      }

      return primaryImageUri;
    },
  },
  mounted() {
    this.$http
      .get(`/deliveries/${this.id}`, this.sessionStore.getHttpConfig)
      .then((result) => {
        const delivery = result.data.data[0];

        this.shopName = delivery.order.shop.name;
        this.shopOwnerName = `${delivery.order.shop.owners[0].firstname} ${delivery.order.shop.owners[0].lastname}`;

        this.orderStatus = delivery.order.status;

        this.orderPaymentType = delivery.order.payment_type;

        const deliveryDateObj = new Date(delivery.delivery_date);
        this.deliveryDate = deliveryDateObj.toISOString().split("T")[0];

        this.deliveryStatus = delivery.status;

        this.deliveryAddress = delivery.order.address_detail.address_line_1;

        if (delivery.driver) {
          this.assignedDriver = delivery.driver.id;
        }

        if (delivery.order.address_detail.address_line_2 && delivery.order.address_detail.address_line_1 !== delivery.order.address_detail.address_line_2) {
          this.deliveryAddress += `, ${delivery.order.address_detail.address_line_2}`;
        }

        if (delivery.order.address_detail.address_line_3 && delivery.order.address_detail.address_line_1 !== delivery.order.address_detail.address_line_3) {
          this.deliveryAddress += `, ${delivery.order.address_detail.address_line_3}`;
        }

        if (delivery.order.address_detail.surburb) {
          this.deliveryAddress += `, ${delivery.order.address_detail.surburb}`;
        }

        if (delivery.order.address_detail.city) {
          this.deliveryAddress += `, ${delivery.order.address_detail.city}`;
        }

        if (delivery.order.address_detail.province) {
          this.deliveryAddress += `, ${delivery.order.address_detail.province}`;
        }

        this.orderDetails = Object.assign({}, delivery.order);

        this.orderProducts.splice(0);
        for (let i = 0; i < delivery.order.products.length; i++) {
          this.orderProducts.push(delivery.order.products[i]);
        }

        if (this.orderDetails.packer) {
          this.orderPacker = this.orderDetails.packer.id;
        }

        // this.setOrderNotes(this.orderDetails.notes);
        this.orderNotes = this.orderDetails.notes;
        this.deliveryNotes = delivery.notes;

        if (delivery.order.tags === "credit") {
          this.isCreditOrder = true;
        }
      })
      .catch((error) => {
        this.handleHttpError(
          error,
          "An error occurred while loading the delivery details. Please try again later or contact support."
        );
      });

    this.$http
      .get("/drivers", this.sessionStore.getHttpConfig)
      .then((result) => {
        const drivers = result.data.data;

        this.driverList.splice(0);
        this.driverUserList.splice(0);
        for (let i = 0; i < drivers.length; i++) {
          this.driverList.push(drivers[i]);
          this.driverUserList.push({
            driverId: drivers[i].id,
            userId: drivers[i].user.id,
            friendlyName: `${drivers[i].user.firstname} ${drivers[i].user.lastname}`,
          });
        }
      })
      .catch((error) => {
        this.handleHttpError(
          error,
          "An error occurred while loading driver details. Please try again later or contact support."
        );
      });

    this.fetchPackers();
  },
};
</script>
